import TapperImg from "../images/productsPage/product1.png";
import PainterImg from "../images/productsPage/product2.jpg";

const productList = [
    {
        url: TapperImg,
        title: 'RoboTapper',
        width: '100%'
    },
    {
        url: PainterImg,
        title: 'RoboPainter',
        width: '100%'
    }
];

export default productList;