import Darwin from "../images/aboutUsPage/darwin.png";
import Jimmy from "../images/aboutUsPage/jimmy.png";
import Anthony from "../images/aboutUsPage/anthony.png";
import Eric from "../images/aboutUsPage/eric.png";

const team = [
    {
        name: "Prof. Darwin Tat Ming LAU",
        description: "Founder",
        image: Darwin
    },
    {
        name: "Prof. Jimmy Ho Man LEE",
        description: "Founder",
        image: Jimmy
    },
    {
        name: "Dr. Anthony K.W. SUM",
        description: "Founder",
        image: Anthony
    },
    {
        name: "Eric WONG",
        description: "CEO",
        image: Eric
    }
];

export default team;