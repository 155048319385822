import ElectricRoad from "../images/casesPage/electric_road.png";
import LogisticCentre from "../images/casesPage/logistic_centre.png";
import YuanLongSchool from "../images/casesPage/yuan_long_school.png";
import MW from  "../images/casesPage/mw.png";

const tapperCases = [
    ElectricRoad,
    LogisticCentre,
    YuanLongSchool,
    MW
]

export default tapperCases;