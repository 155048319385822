import LivenHouse from "../images/casesPage/liven_house.png";
import MarineOffice from "../images/casesPage/tai_po_marine_office.png";
import SquashCentre from "../images/casesPage/squash_centre.png";
import CLSSchool from "../images/casesPage/cls_school.png";
import TSKSchool from "../images/casesPage/tsk_school.png";
import OldBailey from "../images/casesPage/old_bailey.png";
import SwimmingPool from "../images/casesPage/swimming_pool.png";
import PokFuLam from "../images/casesPage/pok_fu_lam.png";
import CityHall from "../images/casesPage/city_hall.png";
import MTR from "../images/casesPage/mtr.png";
import TsengChoiStreet from "../images/casesPage/tseng_choi_street.png";
import ElectricRoad from "../images/casesPage/electric_road.png";
import LogisticCentre from "../images/casesPage/logistic_centre.png";
import YuanLongSchool from "../images/casesPage/yuan_long_school.png";

const tapperCases = [
    {
        location: "Pok Fu Lam Fire Station Officers Married Quarters",
        image: PokFuLam
    },
    {
        location: "Hong Kong City Hall",
        image: CityHall
    },
    {
        location: "Tsing Yi Station Car Park",
        image: MTR
    },
    {
        location: "Tseng Choi Street Community Hall",
        image: TsengChoiStreet
    },
    {
        location: "Electric Road Market and Cooked Food Centre",
        image: ElectricRoad
    },
    {
        location: "Chai Wan Government Logistic Centre",
        image: LogisticCentre
    },
    {
        location: "Yuan Long Public Secondary School",
        image: YuanLongSchool
    },
    {
        location: "Kwun Tong Liven House",
        image: LivenHouse
    },
    {
        location: "Tai Po Marine Office",
        image: MarineOffice
    },
    {
        location: "Hong Kong Squash Centre",
        image: SquashCentre
    },
    {
        location: "Chiu Lut Sau Memorial Secondary School",
        image: CLSSchool
    },
    {
        location: "Tang Shiu Kin Victoria Government Secondary School",
        image: TSKSchool
    },
    {
        location: "Old Bailey Street Police Married Quarters",
        image: OldBailey
    },
    {
        location: "Chai Wan Swimming Pool",
        image: SwimmingPool
    }
];

export default tapperCases;